<template>
  <v-card class="mt-10 mb-10">
    <v-card-title>הוספת עסק חדש</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form">
        <v-row class="basic mt-2">
          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              v-model="company.name"
              background-color="white"
              outlined
              dense
              @keydown="name_exist = false"
              :rules="[(v) => !!v || 'שדה חובה', !name_exist || 'שדה חובה']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                שם
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              v-model="company.company_phone"
              background-color="white"
              outlined
              dense
              type="number"
              :rules="[
                (v) => !!v || 'שדה חובה',
                !company_phone_exist || 'שדה חובה',
              ]"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                מספר טלפון
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              v-model="company.id_number"
              background-color="white"
              outlined
              dense
              type="number"
              :rules="[
                (v) => !!v || 'שדה חובה',
                !id_number_exist || 'שדה חובה',
              ]"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                ח.ט
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              v-model="company.email"
              background-color="white"
              outlined
              dense
              :rules="[(v) => !!v || 'שדה חובה', !email_exist || 'שדה חובה']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                מייל
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              v-model="company.phone_number"
              background-color="white"
              outlined
              dense
              type="number"
              :rules="[
                (v) => !!v || 'שדה חובה',
                !phone_number_exist || 'שדה חובה',
              ]"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                מספר נייד
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              v-model="company.admin_password"
              background-color="white"
              outlined
              dense
              type="password"
              :rules="[(v) => !!v || 'שדה חובה']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                סיסמה
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              v-model="company.country"
              background-color="white"
              outlined
              dense
              label="עיר"
            >
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              outlined
              dense
              label="כתובת"
              v-model="company.address"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              @change="select_all_permissions($event)"
              color="green"
              label="تحديد الكل"
            ></v-checkbox>
            <v-divider></v-divider>
            <div class="d-flex flex-wrap white">
              <v-checkbox
                v-for="(per, i) in permissions"
                :key="i"
                :label="per.text"
                :value="per.value"
                v-model="company.permissions"
                class="ms-5"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              readonly
              outlined
              dense
              label="סטטוס"
              :value="company.status ? 'פעיל' : 'לא פעיל'"
            >
              <template slot="append">
                <v-switch
                  v-model="company.status"
                  class="mt-0"
                  hide-details
                ></v-switch>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end px-4">
      <div class="d-flex">
        <v-btn
          @click="close_form()"
          x-large
          depressed
          height="40"
          color="black--text"
        >
          <v-icon>arrow_forward</v-icon>
        </v-btn>
        <v-btn
          @click="save()"
          :disabled="save_loader"
          :loading="save_loader"
          x-large
          depressed
          height="40"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import permissions_data from "../../../data/permiassions";
export default {
  name: "Form",
  data() {
    return {
      permissions: permissions_data,
      save_loader: false,
      name_exist: false,
      company_phone_exist: false,
      id_number_exist: false,
      email_exist: false,
      phone_number_exist: false,
      company: {
        name: "0107823629",
        company_phone: "0107823629",
        phone_number: "0107823629",
        admin_password: "0107823629",
        id_number: "0107823629",
        country: "0107823629",
        email: "0107823629",
        address: "0107823629",
        status: true,
        permissions: [],
      },
    };
  },
  methods: {
    select_all_permissions(ev) {
      if (ev) {
        const map = this.permissions.map(function (val) {
          return val.value;
        });
        this.company.permissions = Object.assign([],map );
      } else {
        this.company.permissions = [];
      }
    },
    close_form() {
      if (this.save_loader) {
        this.$emit("update_table");
        this.save_loader = false;
      }
      this.$emit("close_form");
    },

    save() {
      if (this.$refs.form.validate() && !this.save_loader) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "super/companies",
              method: "POST",
            },
            data: this.company,
          })
          .then((res) => {
            this.close_form();
          })
          .catch((err) => {
            this.save_loader = false;
            let errors = err.response.data.data;
            Object.keys(errors).forEach((e) => {
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this[`${e}_exist`] = true;
                }
              });
            });
            console.log(err.response);
          });
      }
    },
  },
};
</script>
