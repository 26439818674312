<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <Table
          @data_loaded="data_loaded = true"
          :data_loaded="data_loaded"
          @close_form="close_form()"
          ref="table"
        ></Table>
        <v-btn
          elevation="8"
          class="mt-5"
          v-if="!form && data_loaded"
          @click="form = !form"
          fab
          small
        >
          <v-icon>add</v-icon>
        </v-btn>
        <Form
          ref="form"
          @update_table="update_table()"
          @close_form="form = !form"
          v-if="form"
        ></Form>
      </div>

    </div>
  </v-container>
</template>

<script>
import Table from "@/components/super_admin/companies/table";
import Form from "@/components/super_admin/companies/form";
export default {
  name: "employees_page",
  data() {
    return {
      form: false,
      data_loaded: false,
    };
  },
  components: {
    Table,
    Form,
  },
  methods: {
    update_table() {
      this.$refs.table.get_data();
    },
    close_form() {
      if (this.$refs.form) {
        this.$refs.form.close_form();
      }
    },
  },
};
</script>
